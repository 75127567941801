/*imports*/
@tailwind base;
@tailwind components;
@tailwind utilities;
/*google fonts*/
@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');

.footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

/*homepage rounded bot*/

.roundededge {
  padding-top: 6rem;
border-top-left-radius: 50%;
border-top-right-radius: 50%;
}

/*service Background*/

.serviceBG {
  background: url("./components/images/bulb.jpg") no-repeat center;
  background-size:cover;
}

/*selection colour*/

::selection {
  background: #F198FF; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #F198FF; /* Gecko Browsers */
}

/*for pink text*/

.text-pink-conjury::selection {
  background: #43f9ff; /* WebKit/Blink Browsers */
}
.text-pink-conjury::-moz-selection {
  background: #43f9ff; /* Gecko Browsers */
}


/*google fonts*/
body {
  font-family: 'Mukta', sans-serif;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
}


/*dark mode toggle*/

.display-none {
  @apply hidden;
}

/*animation*/
/*header text*/
.fadein {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.fadeinfast {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

/*homepage images*/
.homeimage1 {
  margin-left: -5rem;
}


/*animations*/

.fadeinslow {
  animation: fadeInAnimationslow ease 10s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.slide-left {
  opacity: 0;
  transform: translateX(150px);
  animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-right {
  opacity: 0;
  transform: translateX(-150px);
  animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


@layer components {
  .glass {
    @apply backdrop-blur-md bg-white/30 dark:bg-slate-900/30 border border-white/20 dark:border-slate-700/30 shadow-lg;
  }
  
  .glass-card {
    @apply glass rounded-xl p-6 transition-all duration-300;
  }
  
  .glass-button {
    @apply glass rounded-lg px-6 py-3 hover:bg-white/40 dark:hover:bg-slate-800/40 transition-all duration-300 hover:scale-105;
  }
  
  .glass-input {
    @apply backdrop-blur-sm bg-white/20 dark:bg-slate-800/20 border border-white/30 dark:border-slate-700/30 
           rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-pink-500 dark:focus:ring-pink-400
           placeholder-gray-500 dark:placeholder-gray-400 text-gray-800 dark:text-white;
  }
}

/* Enhanced animations */
.hover-float {
  transition: transform 0.3s ease;
}

.hover-float:hover {
  transform: translateY(-8px);
}

/* Add a subtle pulse animation for important elements */
@keyframes subtle-pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
}

.subtle-pulse {
  animation: subtle-pulse 3s ease-in-out infinite;
}

/* Gradient text utility */
.gradient-text {
  @apply bg-gradient-to-r from-pink-500 to-violet-500 text-transparent bg-clip-text;
}

/* Improved shadow for depth */
.shadow-glass {
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
}

/* Tilt effect on hover */
.tilt-on-hover {
  transition: transform 0.3s ease;
}

.tilt-on-hover:hover {
  transform: perspective(1000px) rotateX(2deg) rotateY(-2deg);
}

.zoom-on-hover {
  transition: transform 0.5s ease;
}

.zoom-on-hover:hover {
  transform: scale(1.03);
}

.dark * {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}


@layer utilities {
  .bg-gradient-blur {
    backdrop-filter: blur(64px);
  }
}

@keyframes float {
  0% { transform: translateY(0) translateX(0) rotate(0); }
  25% { transform: translateY(-10px) translateX(10px) rotate(1deg); }
  50% { transform: translateY(0) translateX(20px) rotate(0); }
  75% { transform: translateY(10px) translateX(10px) rotate(-1deg); }
  100% { transform: translateY(0) translateX(0) rotate(0); }
}

@keyframes float-reverse {
  0% { transform: translateY(0) translateX(0) rotate(0); }
  25% { transform: translateY(10px) translateX(-10px) rotate(-1deg); }
  50% { transform: translateY(0) translateX(-20px) rotate(0); }
  75% { transform: translateY(-10px) translateX(-10px) rotate(1deg); }
  100% { transform: translateY(0) translateX(0) rotate(0); }
}

@keyframes pulse-slow {
  0%, 100% { opacity: 0.3; }
  50% { opacity: 0.5; }
}

.animate-float {
  animation: float 15s ease-in-out infinite;
}

.animate-float-reverse {
  animation: float-reverse 20s ease-in-out infinite;
}

.animate-pulse-slow {
  animation: pulse-slow 10s ease-in-out infinite;
}

.blur-3xl {
  filter: blur(64px);
}

.gradient-container {
  transform: translateZ(0);
  will-change: transform;
}

.contactScreen {
height:  80vh;

}